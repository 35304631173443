import React from "react"
import { Link, graphql } from "gatsby"
  import {
    MdHelp,
    MdArrowBack,
    MdSupervisorAccount,
    MdCheck,
    MdArrowForward,
    MdAssignment,
    MdList
  } from 'react-icons/md';


import SEO from "../components/seo"
import Layout from "../components/layout"

const SupportTec = ({data: {image1,soporte }}) => {


    let imgProd = soporte.childImageSharp.fluid.src;

    return (
        <Layout changeHeader={2}>
            <SEO title={`Suporte técnico`} />

            <section className="hero-wrap hero-wrap-2 mb-2" style={{backgroundImage: `url(${image1.childImageSharp.fluid.src})`}} data-stellar-background-ratio="0.5">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                    <div className="col-md-1" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                        <Link to ="/#services-section" className="icon ml-5 btn btn-primary" title="Regresar">
                            <MdArrowBack
                                color="#fff"
                                size="2.3em"
                            /> 
                        </Link>
                    </div>
                    <div className="col-md-11 text-center">
                        <h1 className="mb-2 bread" data-sal="slide-left" data-sal-duration="2000" data-sal-easing="ease">Soporte técnico </h1>
                        <p className="breadcrumbs"><span className="mr-2">
                            <Link  className="" to="/#services-section">Servicios</Link> > </span> <span>Soporte técnico</span>
                        </p>
                    </div>
                    </div>
                </div>
            </section>

            <section className="text-justify">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <div className="sidebar-box" data-sal="slide-left" data-sal-duration="1500" data-sal-easing="ease">
                                <h3 className="text-center">Soporte técnico</h3>

                                <p>&nbsp;&nbsp;&nbsp;El soporte técnico es una asistencia que brindan las empresas para que sus clientes puedan hacer uso de sus productos o servicios. La finalidad del soporte técnico es ayudar a los usuarios para que puedan resolver ciertos problemas.

                                Por lo general, las empresas de informática cuentan con soporte técnico a disposición de sus usuarios. La atención se brinda por teléfono, a través de Internet o, en algunos casos, mediante visitas del personal al domicilio del cliente.</p>

                                <h3 className="text-center">¿Por qué es importante?</h3>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;Cuando la empresa adquiere un producto o servicio tecnológico, ya sea hardware o software, busca mejorar su infraestructura, su negocio y el servicio al cliente. Teniendo en cuenta que estos son aspectos indispensables en las empresas, se debe tener presente que las implementaciones requieren siempre de asistencia de personal que se encargue del funcionamiento y estabilización del mismo.</p>

                                <p>&nbsp;&nbsp;El servicio técnico no es simplemente una asistencia de reparación o mantenimiento: es la imagen de la empresa; y el técnico es una parte fundamental de esa imagen.</p>                                           
                            </div>
                        </div>

                        <div className="col-md-6 col-12 p-md-7 mt-5" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease" >
                            <img src={imgProd} alt="img project" className="img-fluid rounded mb-1" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-section contact-section ftco-no-pb" id="contact-section" style={{padding: '2em 0'}}>
                <div className="container-fluid mb-4">
                    <div className="row justify-content-center mb-1 pb-3">
                        <div className="col-md-7 heading-section text-center " data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                            <h3 className="mb-4">Pasos para el Soporte Técnico</h3>
                        </div>
                    </div>
                    <div className="row d-flex contact-info mb-0 justify-content-center mb-2">
                        <div className="col-md-4 col-lg-2 d-flex " style={{}}> 
                            <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px', background: '#388e3c'}} data-sal="slide-up" data-sal-duration="500" data-sal-easing="ease">
                                <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                                <MdSupervisorAccount color="#ffffff" size="2em"/>
                                </div>
                                <h6 className="">Visita técnica</h6>
                            </div>
                        </div>

                        <div className="pt-5 d-none d-sm-none d-md-block" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                        <MdArrowForward
                            color="#33a4db"
                            size="2em"
                        /> 
                        </div>

                        <div className="col-md-4 col-lg-2 d-flex ">
                            <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px',background: '#f9a825'}} data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                                <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                                <MdAssignment color="#ffffff" size="2em"/>
                                </div>
                                <h6 className="">Levantamiento de Información</h6>
                            </div>
                        </div>

                        <div className="pt-5 d-none d-sm-none d-md-none d-lg-block" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                        <MdArrowForward
                            color="#33a4db"
                            size="2em"
                        /> 
                        </div> 
                        
                        <div className="col-md-4 col-lg-2 d-flex ">
                            <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px',background: '#ef6c00'}} data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                                <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                                <MdList color="#ffffff" size="2em"/>
                                </div>
                                <h6 className="">Dianóstico o Requerimientos</h6>
                            </div>
                        </div>

                        <div className="pt-5 d-none d-sm-none d-md-block" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                        <MdArrowForward
                            color="#33a4db"
                            size="2em"
                        /> 
                        </div>

                        <div className="col-md-4 col-lg-2 d-flex">
                            <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px',background: '#039be5' }} data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                                <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                                <MdCheck color="#ffffff" size="2em"/>
                                </div>
                                <h6 className="">Aplicación de solución</h6>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 mt-1 mb-2 heading-section text-center " data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                    <h3 className="mb-4">En Metalinks abarcamos los siguientes tipos de soportes</h3>
                </div>
            </section>

            <section className="ftco-section ftco-no-pb ftco-no-pt ftco-services bg-light mb-5" id="services-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-2 py-5 nav-link-wrap" data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                            <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <a className="nav-link px-0 active title_tabs" id="v-pills-1-tab" data-toggle="pill" href="#v-pills-1" role="tab" aria-controls="v-pills-1" aria-selected="true" title="Consultoría de software y hardware.">
                                    Consultoría &nbsp;
                                    <MdHelp/>
                                </a>
                
                                <a className="nav-link px-0 title_tabs" id="v-pills-2-tab" data-toggle="pill" href="#v-pills-2" role="tab" aria-controls="v-pills-2" aria-selected="false" title="Optimización de recursos">
                                    Optimización
                                    &nbsp;
                                    <MdHelp/>
                                </a>
                
                                <a className="nav-link px-0 title_tabs" id="v-pills-3-tab" data-toggle="pill" href="#v-pills-3" role="tab" aria-controls="v-pills-3" aria-selected="false" title="Soporte remoto y presencial">
                                    Soporte
                                    &nbsp;
                                    <MdHelp/>
                                </a>
                
                                <a className="nav-link px-0 title_tabs" id="v-pills-4-tab" data-toggle="pill" href="#v-pills-4" role="tab" aria-controls="v-pills-4" aria-selected="false" title="Administración de redes y servidores multiplataforma">
                                    Administración
                                    &nbsp;
                                    <MdHelp/>
                                </a>
                                <a className="nav-link px-0 title_tabs" id="v-pills-5-tab" data-toggle="pill" href="#v-pills-5" role="tab" aria-controls="v-pills-4" aria-selected="false" title="Mantenimiento programado.">
                                    Mant. programado
                                    &nbsp;
                                    <MdHelp/>
                                </a>

                                <a className="nav-link px-0 title_tabs" id="v-pills-6-tab" data-toggle="pill" href="#v-pills-6" role="tab" aria-controls="v-pills-4" aria-selected="false" title="Mantenimiento Físico y/o Lógico.">
                                    Mant.  Físico
                                    &nbsp;
                                    <MdHelp/>
                                </a>
                            </div>
                        </div>

                        <div className="col-md-10 p-0 p-md-0 d-flex align-items-center" data-sal="slide-left" data-sal-duration="1500" data-sal-easing="ease">
                        <div className="tab-content pl-md-1" id="v-pills-tabContent">
                            <div className="tab-pane fade show active py-5" id="v-pills-1" role="tabpanel" aria-labelledby="v-pills-1-tab">
                                <div className="p-5">
                                    <h2 className="mb-4">Consultoría de software y hardware</h2>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;El principal objetivo de este tipo de servicios es facilitar la detención y comprensión de problemas dentro de una organización, y después, proporcionar soluciones viables e implantar medidas apropiadas para aumentar la productividad y competitividad de las empresas, así como aprovechar al máximo las funciones que se llevan a cabo.</p>
                                </div>
                            </div>
            
                            <div className="tab-pane fade py-5" id="v-pills-2" role="tabpanel" aria-labelledby="v-pills-2-tab">
                                <div className="p-5">
                                    <h2 className="mb-4">Optimización de recursos.</h2>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;La palabra “optimizar” se refiere a la forma de mejorar alguna acción o trabajo realizada, esto nos da a entender que la optimización de recursos es buscar la forma de mejorar el recurso de una empresa para que esta tenga mejores resultados, mayor eficiencia o mejor eficacia.</p>
                                </div>
                            </div>
            
                            <div className="tab-pane fade py-5" id="v-pills-3" role="tabpanel" aria-labelledby="v-pills-3-tab">
                                <div className="p-5">
                                    <h2 className="mb-4">Soporte remoto y presencial</h2>
                                    <p>El soporte informático remoto, o asistencia informática remota, es un servicio prestado por profesionales de la informática, mediante el cual el técnico se conecta al ordenador del cliente, afectado por necesidades o problemas, a distancia, a través de Internet y con la ayuda de programas de conexión remota.</p>

                                    <p>Es importante mencionar que este tipo de asistencia está sujeto a un contrato previamente definido con el cliente.</p>
                                </div>

                                <section className="ftco-section contact-section ftco-no-pb" id="contact-section" style={{padding: '2em 0'}}>
                                    <div className="container-fluid">
                                        <div className="row justify-content-center mb-1 pb-3">
                                            <div className="col-md-7 heading-section text-center " data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                                                <h3 className="mb-4">Pasos</h3>
                                            </div>
                                        </div>

                                        <div className="row d-flex contact-info mb-0 justify-content-center">
                                            <div className="col-md-4 col-lg-2 d-flex " style={{}}> 
                                                <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px', background: '#388e3c'}} data-sal="slide-up" data-sal-duration="500" data-sal-easing="ease">
                                                    <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                                                    <MdSupervisorAccount color="#ffffff" size="2em"/>
                                                    </div>
                                                    <h6 className="">Entrevista</h6>
                                                </div>
                                            </div>

                                            <div className="pt-5 d-none d-sm-none d-md-block" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                                            <MdArrowForward
                                                color="#33a4db"
                                                size="3em"
                                            />
                                            </div>

                                        <div className="col-md-4 col-lg-2 d-flex ">
                                            <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px',background: '#f9a825'}} data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                                                <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                                                <MdAssignment color="#ffffff" size="2em"/>
                                                </div>
                                                <h6 className="">Presentación de propuesta</h6>
                                        
                                            </div>
                                        </div>

                                        <div className="pt-5 d-none d-sm-none d-md-none d-lg-block" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                                        <MdArrowForward
                                            color="#33a4db"
                                            size="3em"
                                        /> 
                                        </div> 
                                        
                                        <div className="col-md-4 col-lg-2 d-flex ">
                                        <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px',background: '#ef6c00'}} data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                                            <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                                            <MdList color="#ffffff" size="2em"/>
                                            </div>
                                            <h6 className="">Levantamiento de Información</h6>
                                        </div>
                                        </div>

                                        <div className="pt-5 d-none d-sm-none d-md-block" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                                        <MdArrowForward
                                            color="#33a4db"
                                            size="3em"
                                        /> 
                                        </div>

                                        <div className="col-md-4 col-lg-2 d-flex ">
                                        <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px',background: '#039be5' }} data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                                            <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                                            <MdCheck color="#ffffff" size="2em"/>
                                            </div>
                                            <h6 className="">Aplicación de solución</h6>
                                        </div>
                                        </div>

                                    </div>
                                    
                                    </div>
                                </section>
                            </div>
            
                            <div className="tab-pane fade py-5" id="v-pills-4" role="tabpanel" aria-labelledby="v-pills-4-tab">
                                <div className="p-5">
                                    <h2 className="mb-4">Administración de redes y servidores multiplataforma.</h2>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;La Administración de Redes es un conjunto de técnicas tendientes a mantener una red operativa, eficiente, segura, constantemente monitoreada y con una planeación adecuada y propiamente documentada.</p>
                                </div>
                                
                            </div>

                            <div className="tab-pane fade py-5" id="v-pills-5" role="tabpanel" aria-labelledby="v-pills-5-tab">
                                <div className="p-5">
                                    <h2 className="mb-4">Mantenimiento programado</h2>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;El mantenimiento programado sistemático es el grupo de tareas de mantenimiento que se realizan sobre un equipo o instalación siguiendo un programa establecido, según el tiempo de trabajo, la cantidad producida, los kilómetros recorridos, de acuerdo con una periodicidad fija o siguiendo algún otro tipo de ciclo que se repite de forma periódica. Este grupo de tareas se realiza sin importar cuál es la condición del equipo.</p>
                                </div>
                                
                            </div>

                            <div className="tab-pane fade py-5" id="v-pills-6" role="tabpanel" aria-labelledby="v-pills-6-tab">
                                <div className="p-5">
                                    <h2 className="mb-4">Mantenimiento Físico y/o Lógico</h2>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;Es el trabajo realizado en el disco duro con la finalidad de mejorar el rendimiento general del sistema operativo. Y debe ser realizado cuando el sistema operativo presenta una notable reducción de su rendimiento.</p>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default SupportTec

export const pageQuery = graphql`
  query FacBySupport {
    image1: file(relativePath: { eq: "bannerSupport.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    soporte: file(relativePath: { eq: "soporte.jpg" }) {
      childImageSharp {
          fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
          }
      }
    }
   

  }
`